import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { CRow, CCol } from '@coreui/react';
import VehiculosService from "src/services/VehiculosService";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Switch, Collapse, Input, Card, Checkbox, Slider, Table, Space} from 'antd';
import { FormOutlined } from '@ant-design/icons';
import { Link  } from "react-router-dom";
import Archivos from './Archivos.js';
import DriverService from 'src/services/Driver.js';
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import Moment from 'moment'

const { Panel } = Collapse;
const { TextArea } = Input;

const moneyFormat = (value) => new Intl.NumberFormat().format(value);
const marks = {
    0: '0',
    10: '10',
    20: '20',
    30: '30',
    40: '40',
    50: '50',
    60: '60',
    70: '70',
    80: '80',
    90: '90',
    100: '100',
};

const columnsTraslados = [
    {
        title: 'Fecha',
        dataIndex: 'created_at',
        key: 'created_at', //
        render: (text) => Moment(text).format("DD/MM/YYYY h:mm:ss a"),
    },
    {
        title: 'Kilometraje',
        dataIndex: 'km',
        key: 'km',
        render: (text) => moneyFormat(text)+" KM",
    },
    {
        title: 'Dir. Recepción',
        dataIndex: 'direccion_recepcion',
        key: 'direccion_recepcion',
    },
    {
        title: 'Dir. Entrega',
        dataIndex: 'direccion_entrega',
        key: 'direccion_entrega',
    },
    {
        title: 'Estado',
        dataIndex: 'estado',
        key: 'estado',
        render: (text) => <span className={ text === 'Completado' ? "badge rounded-pill text-bg-info" : "badge rounded-pill text-bg-danger"}>{ text }</span>
    },
    {
        title: 'Ver',
        key: 'action',
        render: (_, record) => (
          <>
            <Space size="middle">
              <a href={"/conductor/consultar/"+record.id} target='_blank'>Ver</a>
            </Space>
          </>          
        ),
    }
]
const ViewCar = () =>{
    const [car, setCar] = useState({});
    var { id } = useParams();
    const [photos, setPhotos] = useState([]);
    const [traslados, setTraslados] = useState([]);
    const [isTriplo, setIsTriplo] = useState(false);
    const [vendido, setVendido] = useState(false);
    const [separate, setSeparate] = useState(false);
    const [index, setIndex] = useState(-1);
    var currentImage = photos[index];
    const nextIndex = (index + 1) % photos.length;
    const nextImage = photos[nextIndex] || currentImage;
    const prevIndex = (index + photos.length - 1) % photos.length;
    const prevImage = photos[prevIndex] || currentImage;

    const getStatus = (_car) => {
        const { status_im, published, link_ml } = _car;
    
        if (status_im === 'inactive' && published === 0) {
            return <span className="badge text-bg-danger">No</span>;
        }
    
        if (status_im === 'active') {
            const mercadoLibreBadge = (
                <span className="badge text-bg-warning">
                    <a 
                        style={{ color: 'white', textDecoration: 'none' }} 
                        href={link_ml} 
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        Mercado libre
                    </a>
                </span>
            );
    
            if (published === 1) {
                return (
                    <>
                        {mercadoLibreBadge}&nbsp;&nbsp;
                        <span className="badge text-bg-success">Si</span>
                    </>
                );
            }
    
            return mercadoLibreBadge;
        }
    
        return null; // Por si no cumple ninguna de las condiciones
    };

    const getTraslados = (plate) =>{
        DriverService.getByPlaca(plate).then(resp => {
            setTraslados(resp.data)
        })
    }

    const getCar = async () =>{
        try {
            const resp = await VehiculosService.get(id);
            const { separate, is_sale, is_triplo, pictures, plate } = resp.response;
    
            // Actualiza los estados principales
            setCar(resp.response);
            setSeparate(separate);
            setVendido(is_sale);
            setIsTriplo(is_triplo);
            // Procesa las fotos y actualiza su estado
            const photosArray = pictures.split(',').map((url, index) => ({
                src: url,
                original: url,
                width: "auto",
                height: "auto"
            }));
            setPhotos(photosArray);
    
            // Llama a getTraslados si la placa está disponible
            if (plate) {                
                getTraslados(plate);
            }
        } catch (error) {
            console.error("Error fetching car data:", error);
        }
    }

    const handleClick = (index, item) => {
        currentImage = photos[index]
        setIndex(index);
    }
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);
    
    useEffect(() => {
        getCar();
    }, [])

    return(
        <>
            <CRow>
                <CCol xs={12}>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex align-items-start'>
                            <h4>{car.title} { car.plate ? ' - '+car.plate : '' }</h4>
                            <Link to={"/vehiculos/editar/"+id}>
                                <FormOutlined style={{ fontSize: '10px', marginTop: '11px' }} className='iconCameraTable3' title='Editar'/>
                            </Link>
                        </div>                        
                        <div className='d-flex align-items-center'>
                            <Switch checked={isTriplo} checkedChildren="Triplo" unCheckedChildren="No Triplo" size='medium' className='me-2' disabled />
                            <Switch checked={separate} checkedChildren="Separado" unCheckedChildren="Sin separar" size='medium' className='me-2' disabled/>
                            <Switch checkedChildren="Vendido" unCheckedChildren="No vendido" checked={vendido} size='medium' className='me-2' disabled />         
                            { getStatus(car) }      
                            {/* <div className="progress ms-2" role="progressbar" aria-label="Example with label" aria-valuenow={estadoPro} aria-valuemin="0" aria-valuemax="100">
                                <div className="progress-bar" style={{ width: `${estadoPro}%`}}>{estadoPro}%</div>
                            </div>                       */}
                        </div>
                    </div>
                    <hr />
                    <form>
                        <CRow className='mb-4'>
                            <CCol xs={12}>
                                <Card style={{ width: '100%' }}>
                                    <CRow>
                                        <CCol xs={4}>
                                            <div className='form-group'>
                                                <label>Marca</label>
                                                <p className='mb-0'>{ car.make }</p>
                                            </div>
                                            <div className='form-group'>
                                                <label>Año</label>
                                                <p className='mb-0'>{ car.year }</p>
                                            </div>
                                            <div className='form-group'>
                                                <label>Modelo</label>
                                                <p className='mb-0'>{ car.model }</p>
                                            </div>
                                            <div className='form-group'>
                                                <label>Línea</label>
                                                <p className='mb-0'>{ car.version }</p>
                                            </div>                                            
                                        </CCol>
                                        <CCol xs={4}>
                                            <div className='form-group'>
                                                <label>Tipo de vehículo</label>
                                                <p className='mb-0'>{ car.vehicle_type ? car.vehicle_type : '-' }</p>
                                            </div>
                                            <div className='form-group'>
                                                <label>Tipo de carrocería</label>
                                                <p className='mb-0'>{ car.vehicle_body_type ? car.vehicle_body_type : '-' }</p>
                                            </div>
                                            <div className='form-group'>
                                                <label>Transmisión</label>
                                                <p className='mb-0'>{ car.transmission ?  car.transmission : '-' }</p>
                                            </div>
                                            <div className='form-group'>
                                                <label>Tipo de combustible</label>
                                                <p className='mb-0'>{ car.fuel_type ? car.fuel_type : '-' }</p>
                                            </div>                                            
                                        </CCol>
                                        <CCol xs={4}>
                                            <div className='form-group'>
                                                <label>Color</label>
                                                <p className='mb-0'>{ car.color ? car.color : '-' }</p>
                                            </div>
                                            <div className='form-group'>
                                                <label>Kilometraje</label>
                                                <p className='mb-0'>{ car.kilometers ? moneyFormat(car.kilometers) : moneyFormat(0) } KM</p>
                                            </div>
                                            <div className='form-group input-group-sm'>
                                                <label>Chasis</label>
                                                <p className='mb-0'>{ car.chasis ? car.chasis : '-' }</p>
                                            </div>
                                            <div className='form-group input-group-sm'>
                                                <label>Motor</label>
                                                <p className='mb-0'>{ car.motor ? car.motor : '-' }</p>
                                            </div>                                            
                                        </CCol>
                                    </CRow> 
                                    <CRow>
                                        <CCol xs={4}>
                                            <div className='form-group input-group-sm'>
                                                <label>Cilindraje</label>
                                                <p className='mb-0'>{ car.cilindraje ? car.cilindraje : '-' }</p> 
                                            </div>     
                                            <div className='form-group input-group-sm'>
                                                <label>Fasecolda</label>
                                                <p className='mb-0'>{ car.fasecolda ? car.fasecolda : '-' }</p> 
                                            </div>  
                                            <div className='form-group input-group-sm'>
                                                <label>Impuestos</label>
                                                <p className='mb-0'>{ car.impuestos ? car.impuestos : '-' }</p> 
                                            </div>  
                                        </CCol>
                                        <CCol xs={4}>
                                            <div className='form-group input-group-sm'>
                                                <label>Clase</label>
                                                <p className='mb-0'>{ car.clase ? car.clase : '-' }</p> 
                                            </div>    
                                            <div className='form-group input-group-sm'>
                                                <label>Lugar matricula</label>
                                                <p className='mb-0'>{ car.lugar_matricula ? car.lugar_matricula : '-' }</p> 
                                            </div> 
                                            <div className='form-group input-group-sm'>
                                                <label>Avaluo</label>
                                                <p className='mb-0'>{ car.avaluo ? car.avaluo : '-' }</p> 
                                            </div> 
                                        </CCol>
                                        <CCol xs={4}>
                                            <div className='form-group input-group-sm'>
                                                <label>Servicio</label>
                                                <p className='mb-0'>{ car.servicio ? car.servicio : '-' }</p> 
                                            </div>   
                                            <div className='form-group input-group-sm'>
                                                <label>Fecha matricula</label>
                                                <p className='mb-0'>{ car.fecha_matricula ? car.fecha_matricula : '-' }</p> 
                                            </div> 
                                            <div className='form-group input-group-sm'>
                                                <label>Estado del vehículo</label>
                                                <p className='mb-0'>{ car.estado_general ? car.estado_general : '-' }</p>                                                
                                            </div> 
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol xs={4}>
                                            <div className='form-group input-group-sm'>
                                                <label>Valor SOAT</label>
                                                <p className='mb-0'>{ car.valor_soat ? moneyFormat(car.valor_soat) : moneyFormat(0) } COP</p>
                                            </div>
                                            <div className='form-group input-group-sm'>
                                                <label>Valor Impuesto</label>
                                                <p className='mb-0'>{ car.valor_impuesto ? moneyFormat(car.valor_impuesto) : moneyFormat(0) } COP</p>
                                            </div>
                                            <div className='form-group input-group-sm'>
                                                <label>Estado del vehículo</label>
                                                <p className='mb-0'>{ car.estado_general ? car.estado_general : '-' }</p>                                             
                                            </div>  
                                            <div className='form-group input-group-sm'>
                                                <label>¿Cuántas llaves tiene?</label>
                                                <p className='mb-0'>{ car.segunda_llave ? car.segunda_llave : '-' }</p>
                                            </div>                                          
                                        </CCol>
                                        <CCol xs={4}>
                                            <div className='form-group input-group-sm'>
                                                <label>Fecha SOAT</label>
                                                <p  className='mb-0'>{ car.fecha_soat ? car.fecha_soat : '-' }</p> 
                                            </div>
                                            <div className='form-group input-group-sm'>
                                                <label>Fecha RTM</label>
                                                <p  className='mb-0'>{ car.fecha_rtm ? car.fecha_rtm : '-' }</p> 
                                            </div>
                                            <div className='form-group input-group-sm'>
                                                <label>Nro de dueños</label>
                                                <p className='mb-0'>{ car.duenos ? car.duenos : '-' }</p>
                                            </div>                                            
                                        </CCol>
                                        <CCol xs={4}>
                                            <div><Checkbox disabled name="kit_carretera" checked={ car.kit_carretera }>¿Tiene kit de carretera?</Checkbox></div>
                                            <div><Checkbox disabled name="llanta_repuesto" checked={ car.llanta_repuesto }>¿Tiene llanta de repuesto?</Checkbox></div>
                                            <div><Checkbox disabled name="perno_seguridad" checked={ car.perno_seguridad }>¿Tiene perno de seguridad?</Checkbox></div>
                                            <div><Checkbox disabled name="herramientas" checked={ car.herramientas }>¿Tiene herramientas?</Checkbox></div>
                                            <div><Checkbox disabled name="gato" checked={ car.gato }>¿Tiene gato?</Checkbox></div>
                                            <div><Checkbox disabled name="prenda" checked={ car.prenda }>¿Tiene prenda?</Checkbox></div>
                                            <div><Checkbox disabled name="asegurado" checked={ car.asegurado }>¿Está asegurado?</Checkbox></div>
                                            <div><Checkbox disabled name="mantenimiento" checked={ car.mantenimiento }>¿Mantenimiento?</Checkbox></div>
                                            {
                                                car.mantenimiento ? 
                                                <div className='form-group'>
                                                    <label>Observación de mantenimiento</label>
                                                    <p className='mb-0'>{ car.mantenimiento_obs ? car.mantenimiento_obs : '-' }</p>
                                                </div> : null
                                            }
                                        </CCol>                                        
                                    </CRow>
                                    <CRow>
                                        <CCol xs={4}>
                                            {
                                                car.prenda ? 
                                                <div className='form-group'>
                                                    <label>Observación de prenda</label>
                                                    <p className='mb-0'>{ car.prenda_obs ? car.prenda_obs : '-' }</p>
                                                </div> : null
                                            }                                            
                                            <div className='form-group'>
                                                <label>Estado ruedas delanteras(%)</label>
                                                <Slider marks={marks} defaultValue={50} min={0} max={100} step={10} tooltip={{ open: true }} disabled />
                                            </div>
                                            <div className='form-group input-group-sm'>
                                                <label>Precio</label>
                                                <p className='mb-0'>COP { car.price ? moneyFormat(car.price) : moneyFormat(0) }</p>
                                            </div>
                                        </CCol>
                                        <CCol xs={4}>
                                            {
                                                car.asegurado ? 
                                                <div className='form-group'>
                                                    <label>Observación de prenda</label>
                                                    <p className='mb-0'>{ car.asegurado_obs ? car.asegurado_obs : '-' }</p>
                                                </div> : null
                                            } 
                                            <div className='form-group'>
                                                <label>Estado ruedas traseras(%)</label>
                                                <Slider marks={marks} defaultValue={50} min={0} max={100} step={10} tooltip={{ open: true }} disabled />
                                            </div>
                                            <div className='form-group input-group-sm'>
                                                <label>Placa</label>
                                                <p className='mb-0'>{ car.plate ? car.plate : '-' }</p>
                                            </div> 
                                        </CCol>
                                    </CRow>                                  
                                </Card>
                            </CCol>
                        </CRow>
                        {
                            car.nro_abonos ?
                            <CRow>
                                <CCol xs={12}>
                                    <div className='form-group'>
                                        <label>
                                            Estado de venta&nbsp;
                                            <Link to={"/car-market/contado/editar/"+car.cm_id}>
                                                <FormOutlined className='iconCameraTable2' title='Editar'/>
                                            </Link>
                                        </label>
                                    </div>
                                </CCol>
                                <CCol xs={12}>
                                    <CRow>
                                        <CCol xs={3}>
                                            <div className='form-group'>
                                                <label>Comprador</label>
                                                <p className="mb-0">{ moneyFormat(car.id_comprador) }</p>
                                            </div>
                                        </CCol>
                                        <CCol xs={3}>
                                            <div className='form-group'>
                                                <label>Vendedor</label>
                                                <p className="mb-0">{ moneyFormat(car.id_vendedor) }</p>
                                            </div>
                                        </CCol>
                                        <CCol xs={3}>
                                            <div className='form-group'>
                                                <label>Tipo de separacion</label>
                                                <p className="mb-0">{ (car.tipo_separacion) }</p>
                                            </div>
                                        </CCol>
                                        <CCol xs={3}>
                                            <div className='form-group'>
                                                <label>Responsabilidad de gastos</label>
                                                <p className="mb-0">{ (car.resp_gastos) }</p>
                                            </div>
                                        </CCol>
                                    </CRow>
                                </CCol>
                            </CRow> : null
                        }
                        <CRow>
                            <CCol xs={12}>
                                <Collapse accordion>
                                    <Panel header="Descripción mercado libre" key="1">
                                        <ReactQuill value={ car.description } readOnly />
                                    </Panel>
                                </Collapse>
                            </CCol>
                        </CRow>
                        {
                            car.plate ? 
                            <CRow>
                                <CCol xs={12}>
                                    <Collapse accordion>
                                        <Panel header="Archivos" key="1">
                                            <Archivos car_id={ id } placa={ car.plate }  locale={{ emptyText: 'El vehículo no tiene archivos agregados.' }} />
                                        </Panel>
                                    </Collapse>
                                </CCol>
                            </CRow> : null
                        }                                                
                        <CRow>
                            <CCol xs={12}>
                                <Collapse accordion>
                                    <Panel header="Observaciones" key="1">
                                        <TextArea rows={4} value={ car.observaciones } disabled/>
                                    </Panel>
                                </Collapse>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol xs={12}>
                                <Collapse accordion>
                                    <Panel header="Detalles para publicar" key="1">
                                        <TextArea rows={4} value={ car.detalles } disabled/>
                                    </Panel>
                                </Collapse>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol xs={12}>
                                <Collapse accordion>
                                    <Panel header="Traslados" key="1">
                                        <Table dataSource={traslados} columns={columnsTraslados}  locale={{ emptyText: 'El vehículo no tiene registro de traslados.' }} />;
                                    </Panel>
                                </Collapse>
                            </CCol>
                        </CRow>
                    </form>                    
                </CCol>                
                <CCol xs={12}>
                    <hr />
                </CCol>
                <CCol xs={12}>
                    <Gallery
                        images={photos}
                        onClick={handleClick}
                        enableImageSelection={false}
                    />
                    {!!currentImage && (
                        /* @ts-ignore */
                        <Lightbox
                            mainSrc={currentImage.original}
                            imageTitle={currentImage.caption}
                            mainSrcThumbnail={currentImage.src}
                            nextSrc={nextImage.original}
                            nextSrcThumbnail={nextImage.src}
                            prevSrc={prevImage.original}
                            prevSrcThumbnail={prevImage.src}
                            onCloseRequest={handleClose}
                            onMovePrevRequest={handleMovePrev}
                            onMoveNextRequest={handleMoveNext}
                        />
                    )}
                </CCol>
            </CRow>
        </>
    )
}

export default ViewCar;